<template>
  <div class="b2blayout_bar grid grid-cols-5 container ml-auto mr-auto">
    <router-link
      class="b2blayout_logo col-span-1 flex justify-center items-center"
      to="/"
    >
      <img
        v-if="!absolute"
        src="../assets/layout/color.png"
        class="Logo-Exval-color"
      />
      <img
        v-else
        class="Logo-Exval-color"
        src="../assets/logo-white.svg"
        alt="exval logo"
      />
    </router-link>
    <div class="col-span-4 flex items-center">
      <div
        class="hover:bg-gray lg:hidden col-span-1 col-start-4"
        @click="showMenu"
        style="margin-left: 20px; cursor: pointer"
      >
        <img src="../assets/icons/menu.svg" />
      </div>
      <router-link
        class="col-span-1 Menu_text flex-initial text-primary hidden lg:block"
        to="/nos-outils"
      >
        Nos outils
      </router-link>
      <router-link
        class="col-span-1 Menu_text flex-initial text-primary hidden lg:block"
        to="/tarifs"
      >
        Tarifs
      </router-link>
      <router-link class="hidden" to="/aide"> Aide </router-link>
      <router-link
        class="col-span-1 Menu_text flex-initial text-primary hidden lg:block"
        to="/contact"
      >
        Contact
      </router-link>

      <div class="blk" v-if="responsive == true">
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/nos-outils"
          v-if="responsive == true"
        >
          Nos outils
        </a>
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/tarifs"
          v-if="responsive == true"
        >
          Tarifs
        </a>
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/aide"
          v-if="responsive == false"
        >
          Aide
        </a>
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/contact"
          v-if="responsive == true"
        >
          Contact
        </a>
      </div>
      <!--router-link class="col-span-1 Menu_text Contact flex-initial text-primary hidden lg:block"  to="/contact">
        Contact
      </router-link-->
    </div>
    <div
      class="b2blayout_status col-span-1 flex items-center"
      v-if="user != null"
      style="text-align: right; justify-content: flex-end"
    >
      <div class="" style="position: relative">
        <div class="Menu_text AG flex items-center" style="height: 50px">
          <span
            style="padding: 0 4px 0 10px; text-transform: uppercase"
            v-if="user != null"
            @click="drop"
            >{{ user.firstname.charAt(0) }}{{ user.lastname.charAt(0) }}</span
          >
          <img
            width="16"
            :src="require('../assets/icons/arrow-ios-down.svg')"
            data-bs-toggle="dropdown"
            @click="drop"
            aria-expanded="false"
            v-if="user != null"
          />
          <ul class="dropdown-menu" v-if="user != null && dropdown == true">
            <li>
              <router-link class="dropdown-item" to="/mon-compte"
                >Mon compte</router-link
              >
            </li>
            <li>
              <a
                class="dropdown-item dropdown-menu-end"
                href="#"
                @click.prevent="logout"
                >Se déconnecter</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="col-start-10 col-span-3 grid pr-14 hidden-mobile"
      v-if="user == null"
    >
      <div class="flex justify-center items-center">
        <div class="grid">
          <button class="CTA-3">
            <a
              target="_blank"
              href="https://exval.app/login"
              class="label m-auto"
              >Se connecter</a
            >
          </button>
          <!--button class="CTA-outline-1">
            <router-link to="/inscription" class="label-white m-auto"
              >S'inscrire</router-link
            >
          </button-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "B2bHeader",
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      dropdown: false,
      responsive: false,
    };
  },
  mounted() {
    if (localStorage.getItem("uitoken") != null) {
      const user = JSON.parse(localStorage.getItem("uitoken"));
      this.user = user;
    }
  },
  methods: {
    drop() {
      this.dropdown = !this.dropdown;
    },
    logout() {
      localStorage.removeItem("uitoken");
      window.location.href = "/";
    },
    showMenu() {
      this.responsive = !this.responsive;
    },
  },
};
</script>

<style scoped>
.b2blayout_bar {
  height: 80px;
  position: relative;
  padding: 0px 20px 0px 80px;
  background-color: #ffffff;
}
/* Just mobile */
@media (max-width: 640px) {
  .b2blayout_bar {
    height: 90px;
    padding: 0 0 0 70px;
  }
}
.Logo-Exval-color {
  width: 111.4px;
  height: 32px;
  margin: 4px 80px 4px 0;
  object-fit: contain;
}
/* Just mobile */
@media (max-width: 640px) {
  .Logo-Exval-color {
    margin: 0 80px 0 0;
  }
}
.Menu_text {
  font-size: 16px;
  font-weight: 525;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  margin: 0 10px;
}

.Nos-outils {
  width: 78px;
  height: 24px;
  margin: 0 24px 0 0;
}

.Tarifs {
  width: 45px;
  height: 24px;
  margin: 0 24px;
}

.Aide {
  width: 37px;
  height: 24px;
  margin: 0 24px;
}

.Contact {
  width: 66px;
  height: 24px;
  margin: 0 0 0 24px;
}

.AG {
  height: 24px;
}
.CTA-3 {
  width: 112px;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 16px 0 0;
  padding: NaNpx;
  border-radius: 4px;
  background-color: #f7ac25;
}

.CTA-outline-1 {
  width: 87px;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 0 0 16px;
  padding: NaNpx;
  border-radius: 4px;
  border: solid 1px #ffffff;
}

.label {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}

.label-white {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.blk {
  position: absolute;
  top: 82%;
  z-index: 90;
  background: #fff;
  width: 100%;
  height: auto;
  border: 1px solid #ececec;
  left: 0;
}
.blk a {
  display: block !important;
  position: static;
  padding: 10px 20px;
  margin: 0;
}
/* Just mobile */
@media (max-width: 640px) {
  .hidden-mobile {
    display: none;
  }
}
</style>